import React, { Fragment, useEffect, useState } from "react";
import PersonalContainer from "./personal_information/PersonalContainer";
import util from "../../../components/utils/miniUtils";
import { Tooltip } from "@material-ui/core";
import usePersonalInformation from './personal_information/usePersonalInformation';
import Sidebar from "./sidebar/Sidebar";
import { TalentStore } from "./TalentStore";
import { TALENT_ROLE } from "../../../constants";
import ProfileTalent from "./personal_information/ProfileTalent";
import ProfileScroll from "./ProfileScroll";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faEdit, faStreetView } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { Link as ScrollLink } from 'react-scroll';
import useUploadResume from "./personal_information/upload_resume/useUploadResume";
import VideoPlayer from "./personal_information/videoProfile";
import ProfilePopup from "./ProfilePopup";
export default function ProfileComponent( { talent_id, elevation = 1, hideEmployeeTab = false, modal } ) {
    const { isResumeAvailable } = useUploadResume();

    const query = new URLSearchParams( window.location?.search || "" );
    const opc = query.get( "opc" );
    const [isEditing, setIsEditing] = useState( opc === '1' );
    const [talentState, setTalentState] = useState( {} );
    // Retrieve all methods and setStates you want to share
    const [showModal, setShowModal] = useState( true );

    const handleCloseModal = () => setShowModal( false );
    const { getUserInformation } = usePersonalInformation( talent_id );

    const talent = TalentStore.useState( state => state.talent );
    const education = TalentStore.useState( state => state.talentEducation );
    const socialMedia = TalentStore.useState( state => state.talentSocialMedia );
    const tests = TalentStore.useState( state => state.talentTest );
    const jobsApply = TalentStore.useState( state => state.talentJobsApplied );
    const evaluations = TalentStore.useState( state => state.talentEvaluation );
    // console.log( util.user.role , TALENT_ROLE , talent?.employee_id );


    const handleOpen = () => setOpen( true );
    const handleClose = () => setOpen( false );
    const [open, setOpen] = useState( false );

    useEffect( () => {
        getUserInformation( talent_id ).then( null );

    }, [] );

    // Check local storage for popup visibility
    useEffect( () => {
        const hasShownPopup = localStorage.getItem( 'hasShownPopup' );
        if ( !hasShownPopup ) {
            setShowModal( true ); // Show popup if it hasn't been shown before
            localStorage.setItem( 'hasShownPopup', true ); // Set flag in local storage
        }
    }, [] );

    function handleEditClick() {
        setIsEditing( !isEditing );
    }
    // function handleViewClick(){
    //     setIsEditing( false );
    // }
    useEffect( () => {
        getUserInformation( talent_id ).then( () => {
            if ( opc === '1' ) {
                setIsEditing( true );
            }
        } );
    }, [] );
    console.log( opc, query );
    if ( talent === null ) {
        return util.LOADING_SCREEN( true );
    }


    return (

        <Fragment>
            {showModal && <ProfilePopup show={showModal} handleClose={handleCloseModal} />}
            <div className="container-fluid">
                <VideoPlayer talent_id={talent_id} talentState={talentState} setTalentState={setTalentState} open={open} handleClose={handleClose} handleOpen={handleOpen} />
                <div className="d-flex justify-content-between">
                    <div></div>
                    <div className="d-flex">  <button className=" btn btn-secondary  activeBtn float-right " style={{ height: '30px', padding: '3px', width: '160px', marginRight: '15px' }} onClick={handleOpen} >Upload Intro Video</button>

                        {isEditing ? <button className="btn sizeBtn activeBtn btn-secondary float-right" onClick={handleEditClick}> <FontAwesomeIcon icon={faStreetView} className='isGray mr-1' />View</button> :
                            <button className="btn sizeBtn activeBtn btn-secondary float-right" onClick={handleEditClick}> <FontAwesomeIcon icon={faEdit} className='isGray mr-1' />Edit</button>}
                    </div>  </div>
                <div className="row">
                    {!modal && <div className="col-md-3 talents-detail">
                        <div elevation={elevation} className="scrollable-element bg-white mt-1">
                            <div className="p-1 "><Sidebar /></div>
                            <div>
                                <ul className="nav flex-column">
                                    {talent.employee_id ? <li className="nav-item">
                                        <a className="nav-link nvl">
                                            <ScrollLink
                                                to="employee"
                                                smooth={true}
                                                duration={500}
                                                offset={-70} // Adjust this offset as needed
                                            > <b>Employee </b></ScrollLink>
                                        </a>
                                    </li> : ""
                                    }

                                    {talent.skills.length ? <li className="nav-item">
                                        <a className="nav-link nvl">
                                            <ScrollLink
                                                to="skills"
                                                smooth={true}
                                                duration={500}
                                                offset={-70} // Adjust this offset as needed
                                            > <b>Skills</b></ScrollLink>
                                        </a>
                                    </li> : ""
                                    }
                                    {talent.certifications.length ? <li className="nav-item">
                                        <a className="nav-link nvl">
                                            <ScrollLink
                                                to="certification"
                                                smooth={true}
                                                duration={500}
                                                offset={-70} // Adjust this offset as needed
                                            > <b>Certifications</b></ScrollLink>
                                        </a>
                                    </li> : ""
                                    }
                                    {talent.workHistory.length ? <li className="nav-item">
                                        <a className="nav-link nvl">
                                            <ScrollLink
                                                to="workhistory"
                                                smooth={true}
                                                duration={500}
                                                offset={-70} // Adjust this offset as needed
                                            > <b> Work History</b></ScrollLink>
                                        </a>
                                    </li> : ""
                                    }
                                    {socialMedia.length ? <li className="nav-item">
                                        <a className="nav-link nvl">
                                            <ScrollLink
                                                to="socialmedia"
                                                smooth={true}
                                                duration={500}
                                                offset={-70} // Adjust this offset as needed
                                            > <b>Social Media</b> </ScrollLink>
                                        </a>
                                    </li> : null
                                    }
                                    {education.length ? <li className="nav-item">
                                        <a className="nav-link nvl">
                                            <ScrollLink
                                                to="education"
                                                smooth={true}
                                                duration={500}
                                                offset={-70} // Adjust this offset as needed
                                            > <b>Education</b></ScrollLink>
                                        </a>
                                    </li> : null
                                    }
                                    {jobsApply.length ? <li className="nav-item">
                                        <a className="nav-link nvl">
                                            <ScrollLink
                                                to="jobsapplied"
                                                smooth={true}
                                                duration={500}
                                                offset={-70} // Adjust this offset as needed
                                            ><b>Jobs Applied</b></ScrollLink>
                                        </a>
                                    </li> : ""
                                    }
                                    {talent.notes.length ? <li className="nav-item">
                                        <a className="nav-link nvl">
                                            <ScrollLink
                                                to="notes"
                                                smooth={true}
                                                duration={500}
                                                offset={-70} // Adjust this offset as needed
                                            ><b> Notes</b></ScrollLink>
                                        </a>
                                    </li> : ""
                                    }

                                    {tests.length ? <li className="nav-item">
                                        <a className="nav-link nvl">
                                            <ScrollLink
                                                to="test"
                                                smooth={true}
                                                duration={500}
                                                offset={-70} // Adjust this offset as needed
                                            ><b>Tests</b></ScrollLink>
                                        </a>
                                    </li> : ""
                                    }
                                    {evaluations.length ? <li className="nav-item">
                                        <a className="nav-link nvl">
                                            <ScrollLink
                                                to="evaluations"
                                                smooth={true}
                                                duration={500}
                                                offset={-70} // Adjust this offset as needed
                                            > <b> Evaluations</b></ScrollLink>
                                        </a>
                                    </li> : ""
                                    }
                                    {/* <li className="nav-item">
                                        <a className="nav-link nvl">
                                            <ScrollLink
                                                to="self-intro"
                                                smooth={true}
                                                duration={500}
                                                offset={-70} // Adjust this offset as needed
                                            > <b>Intro Video</b></ScrollLink>
                                        </a>
                                    </li> */}

                                </ul>
                            </div>
                        </div>

                    </div>}
                    <div className={modal ? "col-md-12" : "col-md-9"}>

                        {isEditing ? <><div elevation={elevation} className='mt-2 bg-white'>

                            <div className="p-2"><PersonalContainer talents={talent} talentState={talentState} setTalentState={setTalentState} setIsEditing={setIsEditing} /></div>
                        </div>
                        <ProfileScroll talent_id={talent_id} isEditing={isEditing} talentState={talentState} setTalentState={setTalentState} /></> : (
                            <Fragment>

                                <div elevation={elevation} className='mt-2 bg-white'>

                                    <div className="p-2 talent-box"><ProfileTalent talent_id={talent_id} isResumeAvailable={isResumeAvailable} talentState={talentState} setTalentState={setTalentState} /></div>
                                </div>

                                <ProfileScroll talent_id={talent_id} isEditing={isEditing} talentState={talentState} setTalentState={setTalentState} />
                            </Fragment>
                        )}


                    </div>
                </div>
            </div>
        </Fragment>
    );
}

