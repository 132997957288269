import React, { Fragment, useEffect, useState } from "react";
import { AppBar, CircularProgress, Collapse, Paper, Toolbar, Typography } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkedAlt, faSave } from "@fortawesome/free-solid-svg-icons";
import util from "../utils/miniUtils";
import ImageComponent from '../utils/ImageComponent';

import SweetAlert from "react-bootstrap-sweetalert";
import SkillRow from "./SkillRow";
import Button from "@material-ui/core/Button";
import TalentSkillsEvaluation from "./TalentSkillsEvaluation";
import { ThemeProvider } from "@material-ui/styles";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";

function EvaluateTalentSkills ( { token } ){
    
    const [skillNew, setSkillNew] = useState( { skill_name:"", years_experience:"0" } );
    
    const [state, setState] = useState( {
        firstLoading: true,
        isValidToken: false,
        loading: false,
        skills: [],
        talent: null,
        interviewer: null,
        job: null,
        showLongDesc: false,
        showSA: false,
        expandSkills: false,
        showAddSkill: false,
        skillEvaluationResult: {},
    } );
    
    useEffect( () => {
        getTokenInfo().then( null );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[] );
    
    async function getTokenInfo() {
        try {
            setState( { ...state, loading: true } );
            const request = await util.axios.get( `${util.url}/job/skills_evaluation/?token=${token}` );
            const { error, status, msg, skills, interviewer, job, talent, skillEvaluationResult } = request.data;
            if ( status === 2 ) {
                setState( {
                    ...state,
                    loading: false,
                    isValidToken: false,
                    firstLoading: false
                } );
                return;
            }
            if ( error ) throw msg;
            setState( {
                ...state,
                isValidToken: true,
                skills: Object.assign( [], skills ),
                talent: talent,
                interviewer: interviewer,
                job: job,
                skillEvaluationResult: skillEvaluationResult,
                firstLoading: false,
                loading: false,
            } );
        } catch ( error ) {
            setState( { ...state, loading: false } );
            util.handleError( error );
        }
    }
    
    function handleChanges( e ) {
        const { value } = e.target;
        let t = { ...state };
        t.skillEvaluationResult.notes = value;
        setState( t );
    }
    
    // function isChecked( option ) {
    //     return state.skillEvaluationResult.final_decision === option;
    // }
    
    function checkThisOption( option ) {
        // setState(state => (state.skillEvaluationResult.final_decision = option, state))
        let t = { ...state };
        t.skillEvaluationResult.final_decision = option;
        setState( t );
    }
    
    async function submitEvaluationConfirmed() {
        try {
            /**
             * We only send state.skillEvaluationResult
             * due the skills and ratings already where sent
             * */
            setState( { ...state, loading: true, showSA: false } );
            // console.log( state.skillEvaluationResult );
            if ( util.noValues.includes( state.skillEvaluationResult.final_decision ) ) throw new Error( "Select final decision" );
            if ( util.noValues.includes( state.skillEvaluationResult.notes ) ) throw new Error( "Add final decision notes" );
            
            const request = await util.axios.put( `${util.url}/job/skill_evaluation/finish?token=${token}`, state.skillEvaluationResult );
            const { error, msg } = request.data;
            if ( error ) throw msg;
            setState( {
                ...state,
                isValidToken: false,
                firstLoading: false,
                loading: false
            } );
        } catch ( error ) {
            setState( { ...state, loading: false } );
            util.handleError( error );
        }
        
    }
    
    // Skills related
    function handleChange( e ) {
        const { name, value } = e.target;
        setSkillNew( { ...skillNew, [name]:value } );
    }
    
    async function addNewSkill() {
        try {
            setState( { ...state, loading: true } );
            // Validate the new skill
            if ( skillNew.skill_name === "" ) throw new Error( "Please write the skill name" );
            if ( parseInt( skillNew.years_experience ) === 0 || skillNew.years_experience === "" ) throw new Error( "Please enter years of experience" );
            
            // Create the skill in the talent profile
            let skillNewFull = {};
            skillNewFull.candidate_id       = state.talent.id;
            skillNewFull.skill_type         = 1;
            skillNewFull.rating             = 0;
            skillNewFull.skill_name         = skillNew.skill_name;
            skillNewFull.years_experience   = skillNew.years_experience;
            skillNewFull.skill_evaluation_result_id = state.skillEvaluationResult.id;
            
            const request = await util.axios.put( `${util.url}/evaluator_jobs/add_skill/`, skillNewFull );
            const { error, msg } = request.data;
            if ( error ) throw msg;
            setState( { ...state, loading: false } );
            resetNewSkill();
            
            util.toast().success( msg );
            setState( { ...state, showAddSkill: false } );
            await getTokenInfo();
        }catch ( e ){
            setState( { ...state, loading: false } );
            util.handleError( e );
        }
        
    }
    
    function resetNewSkill() {
        setSkillNew( { skill_name:"", years_experience:"0" } );
    }
    
    async function deleteSkill( index ) {
        try {
            setState( { ...state, loading: true } );
            if ( state.skills.length === 1 ) throw new Error( "You must have at least one skill to evaluate this talent" );
            let skillToDelete = state.skills[index];
            // Send request
            const request = await util.axios.post( `${util.url}/evaluator_jobs/remove_skill/`, skillToDelete );
            const { error, msg } = request.data;
            if ( error ) throw msg;
            // If everything was ok, then delete the skill form the current array
            let tempSkills = [...state.skills];
            delete tempSkills[index];
            // Reindex all the array
            tempSkills = tempSkills.filter( s => s );
            setState( { ...state, skills: tempSkills, loading: false } );
        } catch ( e ) {
            setState( { ...state, loading: false } );
            util.handleError( e );
        }
    }
    
    // Skills related
    
    if ( state.firstLoading ){
        return(
            <div className="w-100 vh-100 pt-5 mt-5">
                <div className="text-center">
                    <CircularProgress color="primary" />
                </div>
            </div>
        );
    }
    
    if ( !state.isValidToken ){
        return(
            <div className="w-100 vh-100 pt-5 mt-5">
                <div className="text-center">
                    <EvaluationFinished />
                </div>
            </div>
        );
    }
    
    const buttons = new TalentSkillsEvaluation();
    
    const styles = {
        buttonBlue: {
            background: '#5cb85c',
            color:"white"
        },
    };

    return (
        <ThemeProvider theme={util.themeEvaluation}>
            <Fragment>
                <AppBar position="sticky" >
                    <div className="container">
                        <Toolbar variant="dense">
                            <Typography variant="h6" color="inherit">
                                eJAmerica
                            </Typography>
                        </Toolbar>
                    </div>
                </AppBar>
                
                <div className="container mt-3 mb-3">
                    <div className="row mb-5">
                        {/* Talent & Job Information */}
                        <Paper className={state.job ? "":"w-100"}>
                            <div className="col-md-12">
                                <Typography variant="h5" component="h2">
                                    Talent - Skills Evaluation
                                </Typography>
                                <hr/>
                                <div className="row">
                                    <div className={`col-md-3`}>
                                        <div className="text-center">
                                            {util.isProfilePicAvailable( state.talent, true, 100 )}
                                        </div>
                                        {/*<hr/>*/}
                                        <h4>{state.talent.name}</h4>
                                        <p>{util.isResumeAvailable( state.talent ) !== "" ? util.isResumeAvailable( state.talent, true ) : "No resume available"}</p>
                                        <p><FontAwesomeIcon icon={faMapMarkedAlt} /> {util.location( state.talent.country,state.talent.state,state.talent.city )}</p>
                                    </div>
                                    <div className="col-md-9">
                                        {state.job ?
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <h4>{state.job.job_title}</h4>
                                                        <Collapse in={state.showLongDesc} collapsedHeight={100}>
                                                            <div dangerouslySetInnerHTML={{ __html: state.job.job_description }} />
                                                        </Collapse>
                                                        
                                                        <div className="d-flex justify-content-end">
                                                            <Button
                                                                size="small"
                                                                color="primary"
                                                                variant="contained"
                                                                className="mb-3"
                                                                onClick={()=> setState( { ...state, showLongDesc: !state.showLongDesc } )}
                                                            >
                                                                {state.showLongDesc ? "Show less":"Show more"}
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            :""}
                                    </div>
                                </div>
                            </div>
                        </Paper>
                        
                        {/* Skills */}
                        <Paper className="w-100 mt-3">
                            <div className="col-md-12">
                                {
                                    state.isFinished ?
                                        <Fragment>
                                            <div>
                                                <h3>Final decision</h3>
                                                <p><b>Feedback:</b></p>
                                                <p className="quoteFeedback"><q>{state.skillEvaluationResult.notes}</q></p>
                                            </div>
                                        </Fragment>
                                        :
                                        ""
                                }
                                
                                {
                                    state.isFinished ?
                                        ""
                                        :
                                        <div className="pt-2">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <Typography variant="h5" component="h2">
                                                    Skill Evaluation
                                                </Typography>
                                                {state.showAddSkill ? buttons.AddSkillHidden( state, setState, resetNewSkill ) : buttons.AddSkillShow( state, setState )}
                                            </div>
                                            
                                            <Collapse in={state.showAddSkill}>
                                                <table className="w-100">
                                                    <thead/>
                                                    <tbody>
                                                        <tr>
                                                            <td width="60%">
                                                                <util.RowComponent
                                                                    c={12}
                                                                    t={"Skill Name"}
                                                                    n={"skill_name"}
                                                                    m={handleChange}
                                                                    v={skillNew.skill_name}
                                                                />
                                                            </td>
                                                            <td width="20%">
                                                                <util.RowComponent
                                                                    c={12}
                                                                    t={"Years of Experience"}
                                                                    n={"years_experience"}
                                                                    ty={"number"}
                                                                    m={handleChange}
                                                                    v={skillNew.years_experience}
                                                                />
                                                            </td>
                                                            <td width="20%" style={{ textAlign:"center" }}>
                                                                <Button
                                                                    color={"primary"}
                                                                    variant={"contained"}
                                                                    size={"small"}
                                                                    style={{ ...styles.buttonBlue }}
                                                                    onClick={addNewSkill}>
                                                                    <FontAwesomeIcon icon={faSave} className="mr-1"  />
                                                                Save Skill
                                                                </Button>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </Collapse>
                                            <hr/>
                                        </div>
                                }
                                
                                {state.skills.map( ( skill, index )=>{
                                    return (
                                        <SkillRow
                                            expandSkills={state.expandSkills}
                                            key={skill.id}
                                            index={index}
                                            skillSingle={skill}
                                            deleteSkill={deleteSkill}
                                            interviewId={state.skillEvaluationResult.id}
                                            isFinished={state.isFinished}
                                        />
                                    );
                                } )}
                            </div>
                        </Paper>
                        
                        {/* Final decision*/}
                        <Paper className="w-100 mt-3">
                            <div className="col-md-12" >
                                <div className="text-left">
                                    {state.isFinished ?
                                        "":
                                        <Fragment>
                                            <Typography variant="h5" component="h2">
                                                Final decision
                                            </Typography>
                                            <hr/>
                                            <div className="text-center">
                                                <ToggleButtonGroup
                                                    size="small"
                                                    value={state.skillEvaluationResult.final_decision}
                                                    exclusive
                                                    onChange={( event, value )=>checkThisOption( value )}
                                                >
                                                    <ToggleButton value="4">
                                                        Selected At Resume Level
                                                    </ToggleButton>
                                                    <ToggleButton value="5">
                                                        Rejected At Resume Level
                                                    </ToggleButton>
                                                    <ToggleButton value="1">
                                                        Selected At Interview Level
                                                    </ToggleButton>
                                                    <ToggleButton value="2">
                                                        Rejected At Interview Level
                                                    </ToggleButton>
                                                    <ToggleButton value="3">
                                                        On Hold
                                                    </ToggleButton>
                                                </ToggleButtonGroup>
                                                
                                                <br/>
                                                <div className="text-left">
                                                    <label htmlFor="final_decision_feedback">Comments</label>
                                                </div>
                                                <div className="d-flex align-content-around">
                                                    <textarea
                                                        id="final_decision_feedback"
                                                        onChange={handleChanges}
                                                        className="form-control"
                                                        name="notes"
                                                        defaultValue={state.skillEvaluationResult.notes}
                                                        rows={5}
                                                    />
                                                </div>
                                            </div>
                                            <br/>
                                            <button onClick={()=>{
                                                setState( { ...state, showSA: true } );
                                            }} className="btn btn-md btn-primary">
                                                Submit
                                            </button>
                                            <br/>
                                            <br/>
                                        </Fragment>
                                    }
                                    
                                </div>
                            </div>
                        </Paper>
                    </div>
                </div>
                <br/>
                {util.LOADING_SCREEN( state.loading )}
                {state.showSA ?
                    <SweetAlert
                        show={state.showSA}
                        warning
                        showCancel
                        confirmBtnText="Yes, submit evaluation"
                        confirmBtnBsStyle="success"
                        title="Are you sure?"
                        onConfirm={()=>submitEvaluationConfirmed()}
                        onCancel={()=>setState( { ...state, showSA: false } )}
                        // focusCancelBtn
                    >
                        You will not able to make changes later
                    </SweetAlert>:""}
            </Fragment>
        </ThemeProvider>
    );
    
}

function EvaluationFinished() {
    return(
        <div className="text-center">
            <a href="/">
                <img width="25%" src="/images/demo_logo.svg"  className="desktop" alt="logo"/>
            </a>
            
            <h3 className="mt-3">This skills evaluation is finished</h3>
            <br/>
            <h4><a href="/">eJAmerica</a></h4>
        </div>
    );
}




export default EvaluateTalentSkills;
