import Modal from "react-bootstrap/Modal";
import React, { useEffect, useState } from "react";
import { PositionsStore } from "../stores/PositionsStore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import ReactRecaptcha from "react-recaptcha";
import axios from "axios";
import { bake_cookie } from "sfcookies";
import { toast } from "react-toastify";
import util from "../../utils/miniUtils";
import validations from "../../utils/validations";
import { Form, Formik } from "formik";
import TextField from "@material-ui/core/TextField";
import jwt_decode from "jwt-decode";
import Select from "@material-ui/core/Select";

export default function Login() {

    const show = PositionsStore.useState( state => state.loginModal );
    const [state, setState] = useState( {} );
    const [recaptchaEnabled, setRecaptchaEnabled] = useState( false );

	
    useEffect( () => {
        setTimeout( ()=>{
            if ( !recaptchaEnabled ){
                setRecaptchaEnabled( true );
            }
        }, 1000 );
    },[] );
	
    const handleClose = () => {PositionsStore.update( s => { s.loginModal = false;} );};
	
    const verifyCallBack = data => {
        if ( data ) {
            setState( { ...state, isVerified: true } );
        }
    };
	
    const tryLogin = async ( values ) => {
        try {
            //Validate is human
            if ( !state.isVerified ) return util.handleError( "Validate captcha" );
			
            PositionsStore.update( s => { s.loading = true;} );
            const data = { ...state };
            // Base64 password
            // let enc_password = values.password === "" ? "" : values.password;
            // enc_password = new Buffer( enc_password ).toString( 'base64' );
            data.email = values.email;
            const request = await axios.post( `${util.url}/demo/auth/sign_in`, data );
            const { error, msg, accessToken } = request.data;
            if( error ) {
                PositionsStore.update( s => { s.loading = false;} );
                return util.handleError( msg );
            }
			
            bake_cookie( "tokenServer", accessToken );
			
            const urlParams = new URLSearchParams( window.location.search );
            const jobId = urlParams.get( 'job' );
            toast.success( msg );
            if ( jobId !== null ){
                let u = jwt_decode( accessToken );
                if ( u.role === 2 ){
                    window.history.replaceState( "", "", `/careers?search=&job=${jobId}` );
                    window.location.reload();
                }else{
                    window.history.replaceState( "", "", `/jobs` );
                }
				
            }else{
                // window.history.replaceState( "", "", "/" );
                window.location.reload();
            }
            // Every time the talent log in, it will be redirected to the main page
            setTimeout( ()=>{
                window.location.reload();
            },1500 );
			
			
        } catch ( e ) {
            PositionsStore.update( s => { s.loading = false;} );
            util.handleError( e );
        }
    };
	
    const createAccount = () => {
        setRecaptchaEnabled( false );
        PositionsStore.update( s => {
            s.loginModal = false;
            s.createAccount = true;
        } );
    };
	
    const forgotPassword = () => {
        PositionsStore.update( s=>{
            s.recoveryModal = true;
            s.loginModal    = false;
        } );
    };
	
    const initialValues = {
        email: ""
    };
	
    const logo = "/images/demo_logo.svg";

    return(
        <Modal
            show={show}
            onHide={handleClose}
            size="sm"
        >
            <Modal.Body>
                <div className="mt-4 text-center">
                    <img
                        src={logo}
                        width="150px"
                        alt=""
                    />
                    {state.isError ?
                        <span className="text-danger">
		                  <FontAwesomeIcon icon={faExclamationCircle} />{" "}
                            {state.msg}
		                </span>
                        :
                        ""
                    }
                    <div className="card-body">
                        <Formik
                            initialValues={initialValues}
                            onSubmit={( values ) => {
                                tryLogin( values ).then( null );
                            }}
                        >
                            {( { values, handleChange, touched, errors } ) => (
                                <Form>
                                    <table className="registerTable w-100">
                                        <tbody>
                                            <tr>
                                                <td>
                                                    {/* <TextField
                                                        fullWidth
                                                        id="email"
                                                        name="email"
                                                        label="Email"
                                                        value={values.email}
                                                        onChange={handleChange}
                                                        error={touched.email && Boolean( errors.email )}
                                                        helperText={touched.email && errors.email}
                                                    /> */}
                                                    <Select
                                                        fullWidth
                                                        native
                                                        name="email"
                                                        value={values.email}
                                                        onChange={handleChange}
                                                        error={touched.email && Boolean( errors.email )}
                                                        helperText={touched.email && errors.email}
                                                    >
                                                        <option value="">Choose a role and experience eJRekruit</option>
                                                        <option value="admin@ejamerica.com"> Admin </option>
                                                        <option value="evaluator@ejamerica.com"> Evaluator </option>
                                                        <option value="candidate@ejamerica.com"> Talent </option>
                                                        <option value="client@ejamerica.com"> Client </option>
                                                        <option value="partner@ejamerica.com"> Partner </option>
                                                        <option value="recruiter@ejamerica.com"> Recruiter </option>
                                                        <option value="recruiter.manager@ejamerica.com"> Recruiter Manager </option>
                                                        <option value="employee@ejamerica.com"> Employee </option>
                                                    </Select>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    {/* <TextField
                                                        fullWidth
                                                        type="password"
                                                        id="password"
                                                        name="password"
                                                        label="Password"
                                                        value={values.password}
                                                        onChange={handleChange}
                                                        error={touched.password && Boolean( errors.password )}
                                                        helperText={touched.password && errors.password}
                                                    /> */}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div className="text-center">
                                        {
                                            recaptchaEnabled && show ?
                                                <ReactRecaptcha
                                                    elementID={"diihdhod"}
                                                    sitekey={process.env["REACT_APP_SITE_KEY"]}
                                                    render="explicit"
                                                    // onloadCallback={() => console.log( 'captchaLoaded' )}
                                                    verifyCallback={verifyCallBack}
                                                />
                                                :
                                                null
                                        }
                                    </div>
                                    <div className="form-group mt-2">
                                        <button
                                            type={"submit"}
                                            id="button-login"
                                            data-testid="button-login"
                                            disabled={state.isDisabled}
                                            className="btn btn-primary btn-block"
                                            // onClick={tryLogin}
                                        >
											Login
                                        </button>
                                    </div>
                                </Form>
                            )}
						
                        </Formik>
						
                        <div className="d-flex justify-content-around">
                            <span onClick={forgotPassword} className="likeLink">Forgot Password</span>
                            <span onClick={createAccount} className="likeLink">Create Account</span>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}
