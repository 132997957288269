import React, { Fragment, lazy, Suspense, useEffect, useState } from "react";
import "../assets/css/main.css";
import "../assets/css/global.css";
import "../assets/css/style.css";
import "../components/workload/style.css";
// import "../assets/css/responsive.css";
// import "../assets/css/tableCustom.css";
import {
    Route,
    BrowserRouter as Router,
    Switch,
    NavLink,
    Redirect,
} from "react-router-dom";
import $ from "jquery";
import { bake_cookie, delete_cookie } from "sfcookies";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faBars,
    faBell,
    faKey,
    faSignOutAlt,
    faUser,
} from "@fortawesome/free-solid-svg-icons";
import roleRoutes from "./utils/navigationLinks";
import util from "./utils/miniUtils";
import { Dropdown } from "react-bootstrap";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import { TextField, Tooltip } from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import axios from "axios";
import CryptoJS from 'crypto-js';
import NotificationModal from "./shared/CustomizeNotificactionModal/NotificationModal";
import NotificationDropdown from "./shared/CustomizeNotificactionModal/NotificationDropdown";
import CreateScreeningQuestions from "./screeningQuestions/CreateScreeningQuestions";
import CustomerCompanyLogo from "../components/navbar/components/customerCompanyLogo";
import { PositionsStore } from "./positions/stores/PositionsStore";
const ListOfRequirements = lazy( () => import( "./jobs/components/jobLists/recruitment/ListOfRequirements" ) );
const Employees = lazy( () => import( "./settings/users/employees" ) );
const Customer = lazy( () => import( "./customers/ticketSolution/" ) );
const Appraisal = lazy( () => import( './AppraisalSystem/Appraisal' ) );
const Workload = lazy( () => import( './workload' ) );
const JobsListPartnerClient = lazy( () => import( './jobs/components/jobLists/partner_client/JobsListPartnerClient' ) );
const EvaluationsRole = lazy( () => import( './evaluations/EvaluationsRole' ) );
const AssetsManager = lazy( () => import( './assets_manager/AssetsManager' ) );
const AssignedAssets = lazy( () => import( './assets_manager/AssignedAssets' ) );
const EvaluationsByTalent = lazy( () => import( './evaluations/EvaluationsByTalent' ) );
const MyTalents = lazy( () => import( './jobs/components/jobLists/partner_client/my_talents/MyTalents' ) );
const Dashboard = lazy( () => import( './dashboard' ) );
const Candidates = lazy( () => import( './talents' ) );
const CandidateDetail = lazy( () => import( './talents/components/detailComponents/CandidateDetail' ) );
const Requirements = lazy( () => import( './jobs' ) );
const NewRequirement = lazy( () => import( './jobs/components/NewRequirement' ) );
const PositionDetail = lazy( () => import( './jobs/components/PositionDetail' ) );
const AppSettings = lazy( () => import( './settings/AppSettings' ) );

function SuspenseWrapper( { children } ) {
    return (
        <Suspense fallback={util.LOADING_SCREEN( true )}>
            {children}
        </Suspense>
    );
}


function MainSection( props ) {
    const [showModal, setShowModal] = useState( false );
    const [section, setSection] = useState( "user_account" );
    // const [showNotificationModal,setNotificationModal]=useState( false );

    // function handleCloseNotificationModal() {
    //     setNotificationModal( false );
    // }

    const OnLogout = async( ) => {
        try {
            let userId = {
                id: util.user.id
            };
            
            const request = await util.axios.post( `/candidates/logout`,userId );
            const { error, msg } = request.data;
            if( error ) throw new Error( msg );
            
        } catch ( error ) {
            util.handleError( error );
        }
    };

    async function logoutYa() {
        await OnLogout();
        

        localStorage.removeItem( 'effect' );

        delete_cookie( "tokenServer" );
        props.logOut();
    }
    // console.log( util.user.role );
    function links() {
        switch ( util.user.role ) {
            case 1:
                return roleRoutes.recruiter();
            case 2:
                return roleRoutes.talent( util.user.candidate_id );
            case 3:
                return roleRoutes.client( util.user.id );
            case 4:
                return roleRoutes.partner( util.user.id );
            case 5:
                return roleRoutes.evaluator();
            case 6:
                return roleRoutes.recruiter_manager();
            case 7:
                return roleRoutes.employee();
            case 8:
                return roleRoutes.employee_manager();
            case 9:
                return roleRoutes.customer();
            case 15:
                return roleRoutes.vendor( util.user.id );
            default:
                break;
        }
    }


    useEffect( () => {
        const main = document.getElementById( "main" );
        const initialWidth = window.innerWidth;

        if ( initialWidth < 500 ) {
            $( ".sidebarClass" ).toggleClass( "open" );
            $( "nav" ).toggleClass( "open" );
            main.style.width = "calc(100% - 220px)";
            main.style.width = "100%";
        }

        let width = window.innerWidth;

        $( ".sidebarClass a" ).on( "click", () => {
            if ( width < 500 ) {
                $( ".navbar-toggle" ).trigger( "click" );
            }
        } );
        $( function () {
            $( ".navbar-toggle" ).click( function () {
                $( ".sidebarClass" ).toggleClass( "open" );
                $( "nav" ).toggleClass( "open" );
                if ( width < 500 ) {
                    document.getElementById( "nav" ).className.search( "open" ) === -1
                        ? ( main.className = "invisible" )
                        : ( main.className = "visible" );
                } else {
                    document.getElementById( "nav" ).className.search( "open" ) === -1
                        ? ( main.style.width = "calc(100% - 220px)" )
                        : ( main.style.width = "calc(100% - 70px)" );
                }
            } );
        } );
    }, [] );
    // useEffect(() => {
    //     PositionsStore.update(s => { s.isModalVisible = true; });

    // }, [])

    function handleClose() {
        setShowModal( false );
    }

    async function changeRole( selectedRole ) {
        try {
            let role = 2;
            switch ( selectedRole ) {
                case "recruiter":
                    role = 1;
                    break;
                case "talent":
                    role = 2;
                    break;
                case "client":
                    role = 3;
                    break;
                case "partner":
                    role = 4;
                    break;
                case "evaluator":
                    role = 5;
                    break;
                case "recruiter_manager":
                    role = 6;
                    break;
                case "employee":
                    role = 7;
                    break;
                case "employee_manager":
                    role = 8;
                    break;
                case "vendor":
                    role = 15;
                    break;
                default:
                    break;
            }
            let u = util.user;
            u.role = role;

            const request = await axios.put( `${util.url}/auth/change_user_role`, {
                user: u,
            } );
            const { error, msg, accessToken } = request.data;
            if ( error ) throw msg;
            bake_cookie( "tokenServer", accessToken );
            window.history.replaceState( "", "", "/" );
            window.location.reload();
        } catch ( e ) {
            util.handleError( e );
        }
    }


    function AvailableRoute() {
        let Db = util.user.module_permission.some( permission => permission.module === 'Dashboard' && permission.action === 'View' );
        let JB = util.user.module_permission.some( permission => permission.module === 'Jobs' && permission.action === 'View' );
        let Talents = util.user.module_permission.some( permission => permission.module === 'Talent' && permission.action === 'View' );
        let Assets = util.user.module_permission.some( permission => permission.module === 'Asset' && permission.action === 'View' );
        let Wl = util.user.employee !== null && util.user.module_permission.some( permission => permission.module === 'Workload' && permission.action === 'View' );
        let App = util.user.employee !== null && util.user.module_permission.some( permission => permission.module === 'Appraisal' && permission.action === 'View' );
        let Emp = util.user.module_permission.some( permission => permission.module === 'Employee' && permission.action === 'View' );
        let Settings = util.user.module_permission.some( permission => permission.module === 'Settings' && permission.action === 'View' );
        let customer = util.user.module_permission.some( permission => permission.module === 'Customer' && permission.action === 'View' );

        // Collecting allowed routes
        const routes = [];
        let key = 1;

        if ( util.user.role === 1 ) {
            routes.push( <Route key={key++} exact path="/" render={() => <Redirect to={"/jobs"} />} /> );
            routes.push( <Route key={key++}
                exact
                path="/my-recruitment"
                component={Dashboard}
            /> );
            routes.push( <Route key={key++} exact path="/auth" render={() => <Redirect to={"/jobs"} />} /> );
            routes.push( <Route key={key++} exact path="/jobs" component={Requirements} /> );
            routes.push( <Route key={key++} exact path="/jobs/new" component={NewRequirement} /> );
            routes.push( <Route key={key++} exact path="/jobs/position/detail/:id" component={PositionDetail} /> );
            routes.push( <Route key={key++} exact path="/candidates/:id" component={CandidateDetail} /> );
            routes.push( <Route key={key++} exact path="/profile/:id" component={CandidateDetail} /> );
        }

        if ( util.user.role === 2 ) {
            routes.push( <Route key={key++} exact path="/" render={() => <Redirect to={"/jobs"} />} /> );
            routes.push( <Route key={key++} exact path="/talent/profile" render={() => <Redirect to={"/jobs"} />} /> );
            routes.push( <Route key={key++} exact path="/jobs" component={Requirements} /> );
            routes.push( <Route key={key++} exact path="/jobs/new" component={NewRequirement} /> );
            routes.push( <Route key={key++} exact path="/jobs/position/detail/:id" component={PositionDetail} /> );
            routes.push( <Route key={key++} exact path="/profile/:id" component={CandidateDetail} /> );
        }

        if ( util.user.role === 3 || util.user.role === 4 ) {
            routes.push( <Route key={key++} exact path="/" render={() => <Redirect to={"/my_jobs"} />} /> );
            routes.push( <Route key={key++} exact path="/my_jobs" component={JobsListPartnerClient} /> );
            routes.push( <Route key={key++} exact path="/my_talents" component={MyTalents} /> );
        }

        if ( util.user.role === 5 ) {
            routes.push( <Route key={key++} exact path="/" render={() => <Redirect to={"/evaluations"} />} /> );
            routes.push( <Route key={key++} exact path="/evaluations/" component={EvaluationsRole} /> );
            routes.push( <Route key={key++} exact path="/evaluations_by_talents/" component={EvaluationsByTalent} /> );
        }
        if ( util.user.role === 6 ) {
            routes.push( <Route key={key++} exact path="/" render={() => <Redirect to={"/jobs"} />} /> );
            routes.push( <Route key={key++} exact path="/jobs" component={Requirements} /> );
            routes.push( <Route key={key++} exact path="/jobs/new" component={NewRequirement} /> );
            routes.push( <Route key={key++} exact path="/jobs/position/detail/:id" component={PositionDetail} /> );
            routes.push( <Route key={key++} exact path="/candidates/:id" component={CandidateDetail} /> );
            routes.push( <Route key={key++} exact path="/my_assigned_assets" component={AssignedAssets} /> );
            routes.push( <Route key={key++} exact path="/sq" component={CreateScreeningQuestions} /> );
        }
        if ( util.user.role === 7 ) {

            routes.push( <Route key={key++} exact path="/" render={() => <Redirect to={"/profile/"} />} /> );
            routes.push( <Route key="profile" exact path="/profile/:id" component={CandidateDetail} /> );
        }
        if ( util.user.role === 8 ) {
            routes.push( <Route key={key++} exact path="/" render={() => <Redirect to={"/jobs/"} />} /> );
            routes.push( <Route key="jobs" exact path="/jobs" component={ListOfRequirements} /> );
            routes.push( <Route key={key++} exact path="/jobs/position/detail/:id" component={PositionDetail} /> );
            routes.push( <Route key={key++} exact path="/jobs/new" component={NewRequirement} /> );
            routes.push( <Route key={key++} exact path="/candidates/:id" component={CandidateDetail} /> );
        }
        if ( util.user.role === 15 ) {
            console.log( util.user.role, 'rolelogged' );
            routes.push( <Route key={key++} exact path="/" render={() => <Redirect to={"/jobs"} />} /> );
            routes.push( <Route key={key++}
                exact
                path="/my-recruitment"
                component={Dashboard}
            /> );
            routes.push( <Route key={key++} exact path="/auth" render={() => <Redirect to={"/jobs"} />} /> );
            routes.push( <Route key={key++} exact path="/jobs" component={Requirements} /> );
            routes.push( <Route key={key++} exact path="/jobs/new" component={NewRequirement} /> );
            routes.push( <Route key={key++} exact path="/jobs/position/detail/:id" component={PositionDetail} /> );
            routes.push( <Route key={key++} exact path="/candidates/:id" component={CandidateDetail} /> );
            routes.push( <Route key={key++} exact path="/profile/:id" component={CandidateDetail} /> );

        }
        if ( Db ) routes.push( <Route key="dashboard" exact path="/dashboard" component={Dashboard} /> );
        if ( Talents ) routes.push( <Route key="candidates" exact path="/candidates" component={Candidates} /> );
        if ( Assets ) routes.push( <Route key="assets" exact path="/assets" component={AssetsManager} /> );
        if ( Wl ) routes.push( <Route key="workload" exact path="/workload" component={Workload} /> );
        if ( App ) routes.push( <Route key="appraisal" exact path="/appraisal" component={Appraisal} /> );
        if ( Emp ) routes.push( <Route key="employees" exact path="/employees" component={Employees} /> );
        if ( Settings ) routes.push( <Route key="settings" exact path="/settings" component={AppSettings} /> );
        if ( customer ) routes.push( <Route key="support" exact path="/support" component={Customer} /> );
        return routes;
    }

    function AvailableRole( { selectedRole, role } ) {
        if ( util.user.permissions[selectedRole] === 1 ) {
            return (
                <Dropdown.Item>
                    <div
                        onClick={() =>
                            changeRole( selectedRole, util.user.permissions.recruiter )
                        }
                    >
                        {role}
                    </div>
                </Dropdown.Item>
            );
        } else {
            return "";
        }
    }
    // PositionsStore.update(s => { s.isModalVisible = true; });
    // console.log(position.isModalVisible, "hi")

    return (
        <Fragment>
            {/* {position.isModalVisible ? <GreetingPopup /> : ""} */}

            <Router>
                <nav id="nav" className="navbar navbar-default navbar-fixed">
                    <div className="container-fluid">
                        <div className="d-inline-flex">
                            <button
                                type="button"
                                className="navbar-toggle collapsed"
                                data-toggle="collapse"
                                data-target="#bs-example-navbar-collapse-1"
                                aria-expanded="false"
                            >
                                <FontAwesomeIcon color="#757575" icon={faBars} />
                            </button>
                        </div>
                        <div className="d-inline-flex">
                            {/* { util.user.customer_company_id && util.user.customer_company_id == 183 ? (
                                <span className="">
                                    <img src="/images/encore.svg" height="40" />
                                </span> ) : ""} */}
                            <CustomerCompanyLogo />
                        </div>
                        <div className="d-inline-flex">
                            {/* <Notifications /> */}
                            {util.user.role === 2 ? "" : <NotificationDropdown />}
                            <div>
                                <span className="text-black-50 mr-3">
                                    {util.humanRole( util.user.role )}
                                </span>
                            </div>
                            {/* <socketExample /> */}
                            <Dropdown>
                                <Dropdown.Toggle
                                    variant="secondary"
                                    size="sm"
                                    id="dropdown-basic"
                                >
                                    <FontAwesomeIcon color="#007bff" icon={faUser} />
                                    <span className="ml-1 dropDownBtnText">
                                        {" "}
                                        Hi, {util.user.name}
                                    </span>
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="mainDropdown">
                                    <AvailableRole role="Recruiter" selectedRole="recruiter" />
                                    <AvailableRole role="Talent" selectedRole="talent" />
                                    <AvailableRole role="Client" selectedRole="client" />
                                    <AvailableRole role="Partner" selectedRole="partner" />
                                    <AvailableRole role="Evaluation" selectedRole="evaluator" />
                                    <AvailableRole role="Employee" selectedRole="employee" />
                                    <AvailableRole role="Vendor" selectedRole="vendor" />
                                    {/*<AvailableRole role="Employee Manager" selectedRole="employee_manager" />*/}
                                    <AvailableRole
                                        role="Recruitment Manager"
                                        selectedRole="recruiter_manager"
                                    />
                                    <Dropdown.Divider />
                                    {/* <Dropdown.Item>
                                        <div onClick={() => setNotificationModal( true )}>
                                            <FontAwesomeIcon icon={faBell} className="mr-2" /> Notifications
                                        </div>
                                    </Dropdown.Item> */}
                                    <Dropdown.Divider />
                                    <Dropdown.Item>
                                        <div onClick={() => setShowModal( true )}>
                                            <FontAwesomeIcon icon={faKey} className="mr-2" /> Change
                                            password
                                        </div>
                                    </Dropdown.Item>
                                    <Dropdown.Divider />
                                    <Dropdown.Item>
                                        <div onClick={logoutYa}>
                                            <FontAwesomeIcon icon={faSignOutAlt} className="mr-2" />{" "}
                                            Logout
                                        </div>
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                </nav>
                <div className="sidebarClass">
                    <div className="logo w-100">
                        <a href="/">
                            <img
                                height={50}
                                src="/images/ejem_logo.webp"
                                className="desktop"
                                alt="eJRekruit"
                                loading="lazy"
                            />
                            <img
                                height={50}
                                src="/images/ej_logo.webp"
                                className="mobile"
                                alt="logo"
                            />
                        </a>
                    </div>
                    <ul>
                        {links() &&
                            links().map( ( item ) => {
                                switch ( item.url ) {
                                    case "/":
                                        // Only visible for talents
                                        return (
                                            <li key={item.title}>
                                                <a href={item.url}>
                                                    <i
                                                        className={item.title + " icon"}
                                                        style={{
                                                            backgroundImage:
                                                                "url('/images/" + item.icon + ".svg')",
                                                            color: "gray"
                                                        }}
                                                    />

                                                    <Tooltip title={item.desc ? item.desc : ""}>
                                                        <span>{item.title}</span>
                                                    </Tooltip>
                                                </a>
                                            </li>
                                        );
                                    default:
                                        // Normal view
                                        return (
                                            <li key={item.title}>
                                                <NavLink to={item.url}>
                                                    <i
                                                        className={item.title + " icon"}
                                                        style={{
                                                            backgroundImage:
                                                                "url('/images/" + item.icon + ".svg')",
                                                            color: "gray"
                                                        }}
                                                    />
                                                    <Tooltip title={item.desc ? item.desc : ""}>
                                                        <span>{item.title}</span>
                                                    </Tooltip>
                                                </NavLink>
                                            </li>
                                        );
                                }
                            } )}
                    </ul>
                    <div className="versionOfApp">App Version v1.2.25</div>
                </div>
                <main id="main" className="main">
                    <div className="container-fluid">
                        <Fragment>
                            <SuspenseWrapper>
                                <Switch>
                                    {AvailableRoute()}
                                </Switch>
                            </SuspenseWrapper>

                        </Fragment>
                    </div>
                </main>
            </Router>
            <ChangePasswordModal
                show={showModal}
                handleClose={handleClose}
                backDropOpen={() => null}
                backDropClose={() => null}
            />
            {/* <NotificationModal 
                show={showNotificationModal}
                handleClose={handleCloseNotificationModal}
            /> */}
        </Fragment>
    );
}

export default MainSection;

function ChangePasswordModal( props ) {
    const [currentPassword, setCurrentPassword] = useState( "" );
    const [password, setPassword] = useState( "" );
    const [passwordConfirm, setPasswordConfirm] = useState( "" );
    const [loading, setLoading] = useState( false );

    async function changePasswordRequest() {
        try {
            if ( currentPassword === "" || password === "" || passwordConfirm === "" ) {
                util.toast().warn( "Please fill all the fields" );
                return;
            }
            if ( password.length <= 7 || passwordConfirm.length <= 7 ) {
                util.toast().warn( "Password must at least 8 characters." );
                return;
            }
            if ( password !== passwordConfirm ) {
                util
                    .toast()
                    .warn( "New password and password confirmation are not equal" );
                return;
            }
            props.backDropOpen();
            setLoading( true );
            let data = {
                user_id: util.user.id
            };
            // data.password = new Buffer( password ).toString( 'base64' );
            // data.current_password = new Buffer( currentPassword ).toString( 'base64' );
            data.password = CryptoJS.AES.encrypt( password, process.env.REACT_APP_PASS_KEY ).toString();
            data.current_password = CryptoJS.AES.encrypt( currentPassword, process.env.REACT_APP_PASS_KEY ).toString();
            const request = await util.axios.put(
                `${util.url}/users/update_password`,
                data
            );
            const { error, msg } = request.data;
            util.toast().success( msg );
            if ( error ) throw msg;
            props.backDropClose();
            setLoading( false );
            // // Clean the fields
            setCurrentPassword( "" );
            setPassword( "" );
            setPasswordConfirm( "" );
        } catch ( e ) {
            props.backDropClose();
            setLoading( false );
            util.handleError( e );
        }
    }

    function onChangeHandler( e ) {
        const { name, value } = e.target;
        if ( name === "currentPassword" ) setCurrentPassword( value );
        if ( name === "password" ) setPassword( value );
        if ( name === "passwordConfirm" ) setPasswordConfirm( value );
    }

    return (
        <Fragment>
            <Dialog
                open={props.show}
                onClose={props.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Change your password"}
                </DialogTitle>
                {loading ? (
                    <div style={{ width: "100%", height: "100px" }}>
                        <p>Changing password</p>
                    </div>
                ) : (
                    <div>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                This action will send you a email with a reset password link. We
                                will send this email to the registered account.
                            </DialogContentText>
                            <div className="text-center">
                                <TextField
                                    label="Current password"
                                    type="password"
                                    className="w-50"
                                    name="currentPassword"
                                    value={currentPassword}
                                    onChange={onChangeHandler}
                                />
                            </div>
                            <div className="text-center">
                                <TextField
                                    label="New Password"
                                    type="password"
                                    className="w-50"
                                    name="password"
                                    value={password}
                                    onChange={onChangeHandler}
                                />
                            </div>
                            <div className="text-center">
                                <TextField
                                    label="Password confirmation"
                                    type="password"
                                    className="w-50"
                                    name="passwordConfirm"
                                    value={passwordConfirm}
                                    onChange={onChangeHandler}
                                />
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={props.handleClose} color="default">
                                Cancel
                            </Button>
                            <button
                                onClick={() => changePasswordRequest()}
                                className="btn btn-md btn-primary"
                            >
                                Change Password
                            </button>
                        </DialogActions>
                    </div>
                )}
            </Dialog>
            {util.LOADING_SCREEN( loading )}
        </Fragment>
    );
}