import React, { Fragment, useEffect, useState } from "react";
import util from "../utils/miniUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import QuestionOfTest from "./QuestionOfTest";
import Countdown from 'react-countdown';
import SAlert from "../microComponents/AlertSA";
import { Paper } from "@material-ui/core";
import ImageComponent from "../utils/ImageComponent";


export default function EjTest ( { token } ){
    const [test, setTest] = useState( null );
    const [state, setState] = useState( {
        firstLoading:true,
        isValidToken: false,
        talent: null,
        startTest: null,
        endTest: null,
        testFinished: false,
        showAlert: false,
    } );
	
    useEffect( () => {
        getTestInformation().then( null );
    },[] );
	
    const getTestInformation = async () => {
        try {
            const response = await util.axios.get( `/tests_token?token=${token}` );
            const { error, msg, test, talent } = response.data;
            if ( error ) throw msg;
            setTest( test );

            if ( test.endTest && new Date( test.endTest ).getTime() < new Date().getTime() || test.status === 3 ){
                setState( {
                    ...state,
                    testFinished:true
                } );
            }else{
                setState( {
                    ...state,
                    firstLoading: false,
                    isValidToken: !util.noValues.includes( test.token ),
                    talent: talent,
                    startTest: test.endTest,
                    endTest: test.endTest,
                } );
            }
        } catch ( e ) {
            util.handleError( e );
        }
    };
	
    const startTest = async () => {
        try {
            const response = await util.axios.get( `/startTest?token=${token}` );
            const { error, msg, startTest, endTest, questions } = response.data;
            if ( error ) throw msg;
            setState( {
                ...state,
                startTest: startTest,
                endTest: endTest,
            } );
            setTest( { ...test, questions } );
        } catch ( e ) {
            util.handleError( e );
        }
    };
	
    async function finishTest() {
        try {
            const response = await util.axios.get( `/finish_test?token=${token}` );
            const { error, msg } = response.data;
            if ( error ) throw msg;
            setState( {
                ...state,
                testFinished: true
            } );
        } catch ( e ) {
            util.handleError( e );
        }
    }
	
    function randomRows( array ) {
        let counter = array.length;
        // While there are elements in the array
        while ( counter > 0 ) {
            // Pick a random index
            let index = Math.floor( Math.random() * counter );
			
            // Decrease counter by 1
            counter--;
			
            // And swap the last element with it
            let temp = array[counter];
            array[counter] = array[index];
            array[index] = temp;
        }
        // setRandomized(true)
        return array;
    }
	
    if ( state.testFinished ){
        return(
            <div className="mt-5">
                <div className="text-center">
                    <img  width="250px" src="/images/demo_logo.svg" viewBox="0 0 100 100" className="desktop" alt="logo"/>
                    <h3>Thank you for taking the test. We will get back to you as soon as possible. There is no action pending from you</h3>
                    <h4><a href="/">eJAmerica</a></h4>
                </div>
            </div>
        );
    }
	
    if ( state.firstLoading ){
        return util.LOADING_SCREEN( state.firstLoading );
    }
	
    if ( !state.isValidToken ){
        return "This token is not valid anymore";
    }
	
    return (
        <Fragment>
            <br/>
            <div id="bodyTest" className="container  mb-5">
                <div className="row">
                    <div className="col-md-12">
                        <Paper className="w-100 p-3">
                            <p>Welcome <b>{state.talent.name}</b>,</p>
                            <p>Test name: <b>{test.test.name}</b></p>
                            <p>Time for complete the test: <b>{test.test.time} minutes</b></p>
                            <p dangerouslySetInnerHTML={{ __html: test.test.desc }}/>
                        </Paper>
                        <br/>
                        {!state.startTest ?
                            <Fragment>
                                <div className="text-center">
                                    <button
                                        onClick={startTest}
                                        className="btn btn-primary ">
                                        <FontAwesomeIcon icon={faThumbsUp} size={"2x"} className="mr-2" />
										Start test
                                    </button>
                                </div>
                            </Fragment>
                            :
                            <Fragment>
                                <div className="text-right">
                                    <Countdown
                                        className="countdownStyle"
                                        date={new Date( state.endTest ).getTime()}
                                        onComplete={()=> finishTest()}
                                    />
                                </div>
                                <hr/>
                                {test.questions?.map( ( q,i ) =>{
									
                                    let a = randomRows( [ 1, 2, 3, 4] );
                                    let qw = { ...q };
									
                                    qw.answer1 = q[`answer${a[0]}`];
                                    qw.answer2 = q[`answer${a[1]}`];
                                    qw.answer3 = q[`answer${a[2]}`];
                                    qw.answer4 = q[`answer${a[3]}`];
									
                                    return (
                                        <Fragment key={i}>
                                            <QuestionOfTest question={qw} index={i} />
                                        </Fragment>
                                    );
                                } )}
                                <hr/>
                                <div className="col-md-12 text-right">
                                    <hr/>
                                    <button onClick={()=> setState( { ...state, showAlert: true } )} className="btn btn-sm btn-success">
                                        <FontAwesomeIcon icon={faCheck} className="mr-2"/>
										Finish the test
                                    </button>
                                </div>
                            </Fragment>
                        }
                        <br/>
                    </div>
                </div>
                {state.showAlert ?
                    <SAlert
                        show={state.showAlert}
                        confirmText="Yes"
                        typeButton="warning"
                        title="Are you sure?"
                        msg="Finish the test"
                        hideAlert={() => setState( { ...state, showAlert: false } )}
                        onConfirm={()=> {
                            setState( { ...state, showAlert: false } );
                            finishTest().then( null );
                        }}
                    />:""}
            </div>
        </Fragment>
    );
}