import React, { Component } from "react";
import { bake_cookie } from "sfcookies";
import ReactRecaptcha from 'react-recaptcha';
import axios from "axios";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import util from "../utils/miniUtils";
import ImageComponent from "../utils/ImageComponent";

import RecoverPassword from "./RecoverPassword";
const BASE_URL = process.env.REACT_APP_BASE_URL;

class Login extends Component {
    constructor( props ) {
        super( props );
        this.state = {
            email: "",
            password: "",
            enc_password: "",
            isDisabled: false,
            isError: false,
            msg: "",
            isVerified: false,
            showModal:false
        };
        this.tryLogin = this.tryLogin.bind( this );
        this.verifyCallBack = this.verifyCallBack.bind( this );
    }

  tryLogin = async () => {
      try {
          if ( this.state.email === "" ) throw new Error( "Please enter your email" );
          if ( this.state.password === "" ) throw new Error( "Please enter your password" );
          if ( process.env.REACT_APP_ENVIROMENT === 'Production' ){
              if ( !this.state.isVerified ) throw new Error( "Please verify first" );
          }
          this.setState( { isDisabled: true } );
          const data = this.state;
          data.password = data.enc_password;
          const request = await axios.post( `${BASE_URL}/auth/singing`, data );
          const { error, msg, accessToken } = request.data;
          if ( error ) {
              this.setState( {
                  isError: true,
                  msg: msg
              } );
              this.setState( { isDisabled: false } );
          } else {
              this.setState( { isDisabled: false } );
              bake_cookie( "tokenServer", accessToken );
              bake_cookie( "msg", msg );

              this.props.checkIfLogged( accessToken );
              toast.success( msg );
          }
      } catch ( e ) {
          util.handleError( e );
      }
  };

  onChangeHandler = e => {
      let value = e.target.value;
      const name = e.target.name;
      let enc_password = ( this.state.enc_password === "" ) ? "" : this.state.enc_password;
      if ( name === "password" ) {
          enc_password = new Buffer( value ).toString( 'base64' );
      }
      this.setState( {
          [name]: value,
          enc_password: enc_password
      } );
  };

  captureEnterKey = e => {
      if ( e.key === "Enter" ) {
          this.tryLogin().then( null );
      }
  };

  verifyCallBack = data => {
      if ( data ) {
          this.setState( { isVerified: true } );
      }
  };

  handleClose = () =>{
      this.setState( { showModal:false } );
  }
  tryToRecoverPassword = () =>{
      this.setState( { showModal:true } );
  }

  render() {
      return (
          <div className="container">
              <div className="row">
                  <div className="col-md-4 mx-auto">
                      <div className="card mt-4 text-center">
                          <img
                              src="/images/ejtrackerlogo.png"
                              className="rounded-circle mx-auto d-block m-4 logo"
                              alt=""
                          />
                          <h3>eJRekruit</h3>
                          {this.state.isError ? (
                              <span className="text-danger">
                                  <FontAwesomeIcon icon={faExclamationCircle} />{" "}
                                  {this.state.msg}
                              </span>
                          ) : (
                              ""
                          )}
                          <div className="card-body">
                              <div className="form-group">
                                  <input
                                      id="email"
                                      data-testid="email"
                                      onKeyDown={this.captureEnterKey}
                                      type="text"
                                      value={this.state.email}
                                      onChange={this.onChangeHandler}
                                      name="email"
                                      placeholder="Email"
                                      className="form-control email"
                                      autoFocus
                                  />
                              </div>
                              <div className="form-group">
                                  <input
                                      id="password"
                                      data-testid="password"
                                      onKeyDown={this.captureEnterKey}
                                      type="password"
                                      value={this.state.password}
                                      onChange={this.onChangeHandler}
                                      name="password"
                                      placeholder="Password"
                                      className="form-control"
                                  />
                              </div>
                              <div className="form-group">
                                  <button
                                      id="button-login"
                                      data-testid="button-login"
                                      disabled={this.state.isDisabled}
                                      className="btn btn-primary btn-block"
                                      onClick={this.tryLogin}
                                  >
                      Login
                                  </button>
                              </div>
                              <ReactRecaptcha
                                  sitekey="6LeiiHMoAAAAANXOsX3jP-fNx-vjvpwMRrhii1UP"
                                  render="explicit"
                                  //   onloadCallback={() => console.log( 'captchaLoaded' )}
                                  verifyCallback={this.verifyCallBack}
                              />
                          </div>
                          <div id="ForgotPassword" onClick={()=> this.tryToRecoverPassword()} className="text-black-50 isPointer">Forgot password?</div>
                      </div>
                  </div>
              </div>
              <RecoverPassword show={this.state.showModal} handleClose={this.handleClose}/>
          </div>
      );
  }
}

export default Login;
