import React, { Fragment, useEffect, useMemo, useState } from "react";
import PhoneInput from "react-phone-input-2";
import util from "../../../utils/miniUtils";
import selector from "../../../utils/selector";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import {
    // Collapse,
    InputAdornment,
    TextField,
    // FormControl,InputLabel, Select
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { currency } from '../../../utils/currencies';
import usePersonalInformation from "./usePersonalInformation";
import { TalentStore } from "../TalentStore";
import ResumeContainer from "./upload_resume/ResumeContainer";
import CountryAutoFill from "../../../talents/components/listComponents/CountryAutoFill";
import Location from "../../../talents/components/listComponents/Location";
import { setDefaultLocale } from "javascript-time-ago";
import ResumeUpload from "./upload_resume/ResumeUpload";
import { debounce } from "lodash";

export default function PersonalContainer( { talents,talentState,setTalentState,setIsEditing } ) {
    const {
        // handleChanges,
        setData,
        updateData,
        getListOfJobTitles,
        jobTitles,
        selected,
        setSelected,
        updateJobTitles
    } = usePersonalInformation();

    // const [talentState, setTalentState] = useState( {} );
    const originalTalentsState = { ...talents };
   
    function handleChanges( e ){
        const { name, value } = e.target;
       
        setTalentState( prevState => ( {
            ...prevState,
            [name]: value
        } ) );
      
    }
    // const style = {
    //     maxWidth: 120
    // };
	
    const talent = TalentStore.useState( state => state.talent );
    // const [showMoreFields, setShowMoreFields] = useState( false );
	
    useEffect( () => {
        setSelected( talent.jobTitles );
        // eslint-disable-next-line
    }, [] );
	
    useEffect( () => {
        iconDependingCurrencySelected();
        // eslint-disable-next-line
    }, [talent.currency] );
	
    useEffect( () => {
        getListOfJobTitles();
        // eslint-disable-next-line
    }, [] );
	
    useEffect( () => {
        setTalentState( talent );
    },[talent] );

    if ( talent === null ) {
        return "loading...";
    }
	
    function iconDependingCurrencySelected() {
        for ( let x = 0; x < currency.length; x++ ) {
            const c = currency[x];
            if ( c.code === talent.currency || c.symbol === talent.currency ) {
                return c.symbol;
            }
        }
        return `$`;
    }
    const onUpdate =async () =>{
        await updateData( talentState );
        await updateJobTitles();
        await setIsEditing( false );
    };

    const cancelData = () => {
       
        setTalentState( originalTalentsState );
        setIsEditing( false );
    };
    const debouncedGetListOfJobTitles = debounce( getListOfJobTitles, 1000 );

    
    return (
        <Fragment>
            <div  className="ml-3 mb-3 mt-2">
                <ResumeContainer talent={talent}/>
                {/* <ResumeUpload talent={talent} /> */}
            </div>
            <div className="d-flex">
           
                <div className="col-md-6 mt-2"><util.RowComponentClean
                    c={12}
                    t={"Full Name"}
                    n={"name"}
                    m={handleChanges}
                    v={talentState?.name || ""}
                />
                <div className="mt-3">
                    <util.RowComponentClean
                        disabled={util.user.role === 2}
                        c={12}
                        t={"Email"}
                        n={"email"}
                        m={handleChanges}
                        v={talentState?.email || ""}
                    /></div>
                
                
                <Location
                   
                    data={talentState} // contains everything
                    onChange={handleChanges}
                    setTalentState={setTalentState}
                />
                <div className="d-flex mt-3">
                    <div className="col-md-6">
                   
					
                        <TextField
                            select
                            label="Currency"
                            variant="outlined"
                            value={talentState?.currency}
                            onChange={handleChanges}
                            size={"small"}
                            name="currency"
                            SelectProps={{
                                native: true,
                            }}
                        >
                            {currency.map( ( option ) => (
                                <option key={option.name} value={option.code}>
                                    {option.code} - ${option.name}
                                </option>
                            ) )}
                        </TextField>
                    </div>
                    <div className="col-md-6">
                        <TextField
                            select
                            size={"small"}
                            variant="outlined"
                            name="relocation"
                            className="w-100"
                            label="Relocation"
                            value={talentState?.relocation}
                            onChange={handleChanges}
                            InputLabelProps={{ shrink: true }}
                            SelectProps={{ native: true }}
                        >
						
                            <option value="0">No</option>
                            <option value="1">Yes</option>
						
                        </TextField>
                    </div></div>
                <div className="d-flex mt-3">
                    <div className="col-md-6">
                        <TextField
                            size={"small"}
                            variant="outlined"
                            className="w-100"
                            InputLabelProps={{ shrink: true }}
                            value={talentState?.current_salary ? talentState?.current_salary : 0}
                            type="number"
                            label="Current Salary"
                            fullWidth
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        {iconDependingCurrencySelected()}
                                    </InputAdornment>
                                ),
                            }}
                            name="current_salary"
                            onChange={handleChanges}
                        /> 
                    </div>
                    <div className="col-md-6">
                        <TextField
                            size={"small"}
                            variant="outlined"
                            className="w-100"
                            InputLabelProps={{ shrink: true }}
                            value={talentState?.ctc ? talentState?.ctc : 0}
                            type="number"
                            label="Expected Salary"
                            fullWidth
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        {iconDependingCurrencySelected()}
                                    </InputAdornment>
                                ),
                            }}
                            name="ctc"
                            onChange={handleChanges}
                        />
                    </div></div>
                
                </div>

                <div className="col-md-6 mt-1">
                    <div className="col-md-12 mt-1">
                        <Autocomplete
                            onInputChange={( event, newInputValue ) => {
                                debouncedGetListOfJobTitles( newInputValue );
                                
                            }}
                            onChange={( event, newValue ) => {
                                setSelected( newValue );
                            }}
                            getOptionLabel={option => option.name}
                            value={selected}
                            title="Job Title"
                            multiple
                            // limitTags={3}
                            getOptionSelected={( option, value ) => option.id === value.id}
                            options={jobTitles}
                            // style={{width: 300}}
                            fullWidth
                            renderInput={( params ) => <TextField label="Job Title" {...params} variant="outlined" size='small'/>}
                        />
				
                    </div>
                            
                    <div className={`col-md-12 mt-2`}>
                        <label className="labelFormControlMini">Phone (Home)</label>
                        <PhoneInput
                            placeholder={"Phone (Home)"}
                            country={'us'}
                            name="phone_home"
                            value={talentState?.phone_home || ""}
                            onChange={phone => {
                                let e = { target: { name: "phone_home", value: phone } };
                                handleChanges( e );
                            }}
                        />
                    </div>
							
                    <div className={`col-md-12 mt-2`}>
                        <label className="labelFormControlMini">Phone (Other)</label>
                        <PhoneInput
                            placeholder={"Phone (Other)"}
                            country={'us'}
                            name="phone_other"
                            value={talentState?.phone_other || ""}
                            onChange={phone => {
                                let e = { target: { name: "phone_other", value: phone } };
                                handleChanges( e );
                            }}
                        />
                    </div>
                    <div className="d-flex mt-3">
                        <util.RowComponentClean
                            c={6}
                            t={"Per"}
                            n={"salary_mode"}
                            m={handleChanges}
                            v={util.handleValueByRecruiterCountry( talentState?.salary_mode, 'salary_mode' )}
                            type={1}
                            d={selector.sell_rate_type}
                        />
                        <util.RowComponentClean
                            c={6}
                            t={"Employment Type"}
                            n={"salary_type"}
                            m={handleChanges}
                            v={talentState?.salary_type || ""}
                            type={1}
                            d={[{ salary_type: "Contractor" }, { salary_type: "CorpToCorp(C2C)" }, { salary_type: "Fulltime" }, { salary_type: "Part-time" }, ]}
                        />
                    </div>
                    <div className="col-md-12 mt-4">
                        <util.WorkAuthSelector value={talent.working_status} handleChange={handleChanges} />
                        {talent.working_status=== "other" ?
                            <util.RowComponent
                                c={12}
                                t={"Work Authorization (Others)"}
                                n={"working_status_other"}
                                m={handleChanges}
                                v={talentState?.working_status_other || ""} />
                            
                            : ""}</div>
                    <div className="d-flex mt-3">
                        <util.RowComponentClean
                            c={6}
                            t={"Highest Education"}
                            n={"highestEducation"}
                            m={handleChanges}
                            v={talentState?.highestEducation || ""}
                            type={1}
                            d={util.highestEducation}
                        />

                        <util.RowComponentClean
                            c={6}
                            t={"Last Job Title"}
                            n={"wh_position"}
                            m={handleChanges}
                            v={talentState?.wh_position || ""}
                            // type={1}
                            // d={talent.workHistory}
                        />
                       
                    </div>
                    <div className="d-flex mt-3">
                        <util.RowComponentClean
                            c={6}
                            t={"Notice Period"}
                            n={"noticePeriod"}
                            m={handleChanges}
                            v={talentState?.noticePeriod || "2 Weeks"}
                            type={1}
                            d={selector.AvailabilityToStartObj}
                        />
                        <util.RowComponentClean
                            c={6}
                            t={"How did you find us?"}
                            n={"platform"}
                            m={handleChanges}
                            v={talentState?.platform || ""}
                            type={1}
                            d={[
                                { platform: 'Bayt' },
                                { platform: 'Bumeran' },
                                { platform: 'CareerBuilder' },
                                { platform: 'CareerJet' },
                                { platform: 'Computrabajo' },
                                { platform: 'Dice' },
                                { platform: 'Empleos Maquila' },
                                { platform: 'Empleos Petroleros' },
                                { platform: 'Empleos TI' },
                                { platform: 'Facebook' },
                                { platform: 'Glassdoor' },
                                { platform: 'Indeed' },
                                { platform: 'InfoJobs' },
                                { platform: 'JobStreet' },
                                { platform: 'Konzerta' },
                                { platform: 'LinkedIn' },
                                { platform: 'Monster' },
                                { platform: 'Naukri' },
                                { platform: 'Naukri Resdex' },
                                { platform: 'OCC Mundial' },
                                { platform: 'Reed' },
                                { platform: 'Refferal' },
                                { platform: 'Seek' },
                                { platform: 'SimplyHired' },
                                { platform: 'StepStone' },
                                { platform: 'TechFetch' },
                                { platform: 'Trabajando.com' },
                                { platform: 'Upwork' },
                                { platform: 'www.foundit.in' },
                                { platform: 'Xing' },
                                { platform: 'Ziprecruiter' },
                                { platform: 'Other' },

                            ]}
                        />
                    </div>
                    
                    {/* <div className="col-md-12 mt-2">
                        <label className="labelFormControlMini">Youtube Uploaded Intro video link add here</label>
                        <util.RowComponentClean
                            c={12}
                            t={"Youtube Link"}
                            n={"video_profile"}
                            m={handleChanges}
                            v={talentState?.video_profile || ""}
                        />
                    </div> */}
                   
                </div>
            </div>
			
            <div className="row d-flex mt-3">
                <div className="col-md-12 text-right" >
                    <button
                        onClick={cancelData}
                        className={"btn btn-secondary sizeBtn activeBtn mr-2"}
                    >
                             Cancel
                    </button>
                    <button
                        onClick={() => onUpdate( talentState )}
                        className={"btn btn-secondary sizeBtn activeBtn"}
                    >
                        <FontAwesomeIcon icon={faSave}/> Update
                    </button>
                </div>
            </div>
        </Fragment>
    );
	
}
